import { useEffect, useState } from "react";

import StepOne from "../components/StepOne";
import StepTwo from "../components/StepTwo";
import { API_BASE_URL } from "../const/config";
import { Category } from "../types/Category";
import { Report } from "../types/Report";
import Sidebar from "../components/HomePage/Sidebar";
import CreateCategory from "../components/HomePage/CreateCategory";
import { useForm } from "react-hook-form";

const HomePage = () => {
  const [reports, setReports] = useState<Report[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [currentTab, setCurrentTab] = useState("categories"); // To track the current tab
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState<Partial<Report>>();

  const {
    watch,
    control,
    handleSubmit: handleReportSubmit,
    setValue,
    reset,
  } = useForm<Partial<Report>>({
    defaultValues: {
      report_url: "",
      report_name: "",
      report_type: "",
      is_competition: false,
      image_url: "",
      description: "",
      category_id: undefined,
      alias: "",
      tutorial_video_link: "",
      is_featured_competition: false,
    },
  });

  // Fetch categories from the backend
  const fetchCategories = async () => {
    const token = getToken();

    if (!token) {
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/categories/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include JWT token in Authorization header
        },
      });
      const data = await response.json();
      setCategories(data);
    } catch (err) {
      console.error("Error fetching categories:", err);
    }
  };

  // Fetch categories from the backend
  const fetchReports = async () => {
    const token = getToken();

    if (!token) {
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/reports/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include JWT token in Authorization header
        },
      });
      const data = await response.json();
      setReports(data);
    } catch (err) {
      console.error("Error fetching categories:", err);
    }
  };

  const getToken = () => {
    // Retrieve JWT token from localStorage
    const token = localStorage.getItem("token");

    if (!token) {
      setError("You need to be logged in to perform this action.");
      return;
    }
    return token;
  };

  // Handle form submission for adding new categories
  const handleSubmit = async (data: Partial<Category>) => {
    setError(null);
    setSuccess(null);

    const token = getToken();

    if (!token) {
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/categories/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include JWT token in Authorization header
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const message = await response.json();
        throw new Error(
          message.detail?.[0].msg ?? message.detail ?? "Error creating category"
        );
      }
      setSuccess("Category added successfully!");
      fetchCategories();
    } catch (err: any) {
      setError(err.message || "An error occurred");
      throw new Error(err.message);
    }
  };

  const handleUpdateCategories = async (reorderedCategories: Category[]) => {
    const updateCategories = reorderedCategories.map((c) => ({
      id: c.id,
      position: c.position,
    }));

    setCategories([
      ...categories.filter(
        (c) => reorderedCategories.findIndex((cc) => cc.id === c.id) === -1
      ),
      ...reorderedCategories,
    ]);

    const token = getToken();

    if (!token) {
      return;
    }
    try {
      const response = await fetch(`${API_BASE_URL}/categories/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include JWT token in Authorization header
        },
        body: JSON.stringify(updateCategories),
      });

      if (!response.ok) {
        const message = await response.json();
        throw new Error(message.detail || "Error updating category positions");
      }
    } catch (err: any) {
      setError(err.message || "An error occurred");
    }
  };

  const handleUpdateReports = async (reorderedReports: Report[]) => {
    const updateReports = reorderedReports.map((c) => ({
      id: c.id,
      position: c.position,
      category_id: c.category.id,
    }));

    setReports([
      ...reports.filter(
        (r) => reorderedReports.findIndex((rr) => rr.id === r.id) === -1
      ),
      ...reorderedReports,
    ]);

    const token = getToken();

    if (!token) {
      return;
    }
    try {
      const response = await fetch(`${API_BASE_URL}/reports/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include JWT token in Authorization header
        },
        body: JSON.stringify(updateReports),
      });

      if (!response.ok) {
        const message = await response.json();
        throw new Error(message.detail || "Error updating report positions");
      }
    } catch (err: any) {
      setError(err.message || "An error occurred");
    }
  };

  const handleUpdateCategory = async (
    id: number,
    category: Partial<Category>
  ) => {
    const token = getToken();

    if (!token) {
      return;
    }
    try {
      const response = await fetch(`${API_BASE_URL}/categories/${id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include JWT token in Authorization header
        },
        body: JSON.stringify(category),
      });

      if (!response.ok) {
        const message = await response.json();
        throw new Error(message.detail || "Error updating category");
      }
      setCategories((categories) =>
        categories.map((c) => ({
          ...c,
          ...(c.id === id ? category : {}),
        }))
      );
      setReports((reports) =>
        reports.map((r) => ({
          ...r,
          category:
            r.category.id === id
              ? {
                  ...r.category,
                  ...category,
                }
              : r.category,
        }))
      );
      setError("");
    } catch (err: any) {
      setError(err.message || "An error occurred");
    }
  };

  const handleUpdateReport = async (id: number, report: Partial<Report>) => {
    const category = categories.find(
      (c) => c.id === +(report.category_id ?? 0)
    );

    if (!category) {
      return;
    }
    const token = getToken();

    if (!token) {
      return;
    }
    const currentReport = reports.find((r) => r.id === id);

    if (!currentReport) {
      return;
    }
    report.position =
      currentReport.category.id === category.id
        ? currentReport.position
        : ([...reports]
            .filter((r) => r.category.id === category.id)
            .sort((r1, r2) => r1.position - r2.position)
            .pop()?.position ?? 0) + 1;

    try {
      const response = await fetch(`${API_BASE_URL}/reports/${id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include JWT token in Authorization header
        },
        body: JSON.stringify(report),
      });

      if (!response.ok) {
        const message = await response.json();
        throw new Error(message.detail || "Error updating category");
      }
      setReports((reports) =>
        reports.map((r) => ({
          ...r,
          ...(r.id === id
            ? {
                ...report,
                category,
              }
            : {}),
        }))
      );
      setError("");
    } catch (err: any) {
      setError(err.message || "An error occurred");
    }
  };

  const handleDeleteCategory = async (category: Category) => {
    const token = getToken();

    if (!token) {
      return;
    }
    try {
      const response = await fetch(
        `${API_BASE_URL}/categories/${category.id}/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include JWT token in Authorization header
          },
        }
      );

      if (!response.ok) {
        const message = await response.json();
        throw new Error(message.detail || "Error deleting category");
      }
      fetchCategories();
    } catch (err: any) {
      setError(err.message || "An error occurred");
    }
  };

  const handleDeleteReport = async (report: Report) => {
    const token = getToken();

    if (!token) {
      return;
    }
    await fetch(`${API_BASE_URL}/reports/${report.id}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include JWT token in Authorization header
      },
    });
    fetchReports();
  };

  useEffect(() => {
    fetchCategories();
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue("category_id", "" as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("is_competition")]);

  // Step navigation logic for Report Wizard
  const nextStep = () => setCurrentStep((prev) => prev + 1);
  const prevStep = () => setCurrentStep((prev) => prev - 1);

  const handleNextStep = (data: Partial<Report>) => {
    setFormData(data);
    nextStep();
  };

  const renderStep = () => {
    switch (currentStep) {
      case 2:
        return (
          formData && (
            <StepTwo
              prevStep={prevStep}
              categories={categories}
              formData={formData}
              reset={reset}
            />
          )
        );
      default:
        return (
          <StepOne
            control={control}
            report_type={watch("report_type") ?? ""}
            is_competition={watch("is_competition") ?? false}
            nextStep={handleReportSubmit(handleNextStep)}
            categories={categories}
          />
        );
    }
  };

  return (
    <div className="flex flex-col gap-2 lg:flex-row px-4">
      <div className="lg:h-screen">
        <Sidebar
          categories={categories}
          reports={reports}
          onCategoryUpdate={handleUpdateCategory}
          onCategoriesUpdate={handleUpdateCategories}
          onReportsUpdate={handleUpdateReports}
          onDeleteCategory={handleDeleteCategory}
          onDeleteReport={handleDeleteReport}
          onReportUpdate={handleUpdateReport}
          onReportQueryUpdate={fetchReports}
        />
      </div>

      <div className="container mx-auto my-4 p-3   md:p-6 bg-white shadow rounded border border-slate-100">
        <h1 className="text-xl md:text-2xl font-bold mb-4">
          Report & Category Management
        </h1>

        {/* Tab Navigation */}
        <div className="flex border-b mb-4">
          <button
            className={`px-4 py-2 ${
              currentTab === "categories" ? "border-b-2 border-blue-500" : ""
            }`}
            onClick={() => setCurrentTab("categories")}
          >
            Manage Categories
          </button>
          <button
            className={`px-4 py-2 ${
              currentTab === "report" ? "border-b-2 border-blue-500" : ""
            }`}
            onClick={() => setCurrentTab("report")}
          >
            Report Wizard
          </button>
        </div>

        {/* Tab Panels */}
        {currentTab === "categories" && (
          <div>
            {/* Display success or error messages */}
            {success && (
              <p className="text-green-500 mb-4 p-3 bg-green-50 border-green-100 rounded-md">
                {success}
              </p>
            )}
            {error && (
              <p className="text-red-500 mb-4 p-3 bg-red-50 border border-red-100 rounded-md">
                {error}
              </p>
            )}

            {/* Form to add a new category */}
            <CreateCategory onSubmit={handleSubmit} />
          </div>
        )}

        {currentTab === "report" && (
          <div>
            {/* Report Wizard Section */}
            <h2 className="text-xl font-semibold mb-4">
              Embed Tableau Report Wizard
            </h2>
            {renderStep()}
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePage;
